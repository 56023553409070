import React from "react";

import Navbar from "./Navbar";
import { FiArrowRight } from "react-icons/fi";
import HomeWork from "./HomeWork";

const Home = () => {
  return (
    <div className="home-container">
      <Navbar />
      <div className="home-banner-container">
        <div className="home-text-section">
          <h1 className="primary-heading">
          <span style={{ color: "rgb(11 41 229)" }}>СРОЧНЫЙ</span> выкуп автомобилей{" "}
            <span style={{ color: "rgb(11 41 229)" }}>по ЛУЧШЕЙ цене</span>
          </h1>
          <a
          className="primary-button"
          href="tel:+79885308700"
          style={{ textDecoration: "none", margin: "16px 0" }}
        >
          Позвонить нам
        </a>
        <h3 style={{ margin: "16px 0"}}><a href="tel:+79885308700">+7(988)530-87-00</a></h3>
        <HomeWork/>
        <h2 className="primary-heading2">в Ростове-на-Дону и области</h2>
          <div className="btn-group">
          <a
          className="secondary-button"
          href="tel:+79885308700"
          style={{ textDecoration: "none", margin: "0", color: 'white' }}
        >
          Позвонить нам
        </a>
        <button
            className="secondary-button"
            onClick={() => {
              window.location.href =
                "https://api.whatsapp.com/send?phone=79885308700&text=Здравствуйте,%20интересует%20...";
            }}
          >
            Написать сразу в WhatsApp <FiArrowRight />{" "}
          </button>
        </div>
        </div>
        {/* <div className="home-image-section">
          <Slider />
        </div> */}
      </div>
    </div>
  );
};

export default Home;
