/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react/jsx-no-comment-textnodes */
import React from "react";
import { Element } from "react-scroll";

const Contact = () => {
  return (
    <div className="contact-page-wrapper">
      <Element name="contact" className="element"></Element>
      <h1 className="primary-heading">Где мы находимся?</h1>
      <p className="primary-text" style={{ maxWidth: "800px" }}>
        г. Ростов-на-Дону, улица Малиновского, 214
      </p>
      <a
        className="secondary-button"
        href="tel:+79885308700"
        style={{ textDecoration: "none", marginBottom: "5rem" }}
      >
        Позвонить
      </a>
      <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3A954588daf6307d732bcba36e42555a92daf9bb3e220956572102ad719b694211&amp;source=constructor"
      width="100%"
      height="576"
      frameborder="0"
      ></iframe>
    </div>
  );
};

export default Contact;
